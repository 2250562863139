import * as React from "react";
import ReactPlayer from "react-player";
import "./CampaignGJF24.scss"

import AuthContext from "../../../store/auth-context";


const CFJ24Step0 = ({ campaignData, handleActiveStepChange, setBestellstrecke }) => {
  campaignData.text = "";
  // das hier sollte von der api kommen und dann hier nicht gemacht werden
  const authCtx = React.useContext(AuthContext);
  const authCtxData = JSON.parse(authCtx.data);

  const reactPlayerRef = React.createRef();

  const [videoIndex, setVideoIndex] = React.useState(0);

  const [loopVideo, setLoopVideo] = React.useState(true);

  const [endReached, setEndReached] = React.useState(false);

  // Ref für die Playlist erstellen
  const playlistRef = React.useRef();

  const videoData = [
    {url:"/media/assets/GSK_Junge_Finanzen/09_JuFi24_Festival_PreRoll_20Sek_16x9.mp4",title:"09 JuFi24 Festival PreRoll 20Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/10_JuFi24_Travel_PreRoll_20Sek_16x9.mp4",title:"10 JuFi24 Travel PreRoll 20Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/11_JuFi24_Mountainbike_PreRoll_20Sek_16x9.mp4",title:"11 JuFi24 Mountainbike PreRoll 20Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/12_JuFi24_Segelflug_PreRoll_20Sek_16x9.mp4",title:"12 JuFi24 Segelflug PreRoll 20Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/13_JuFi24_Festival_BumperAd_6Sek_16x9.mp4",title:"13 JuFi24 Festival BumperAd 6Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/14_JuFi24_Travel_BumperAd_6Sek_16x9.mp4",title:"14 JuFi24 Travel BumperAd 6Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/15_JuFi24_Mountainbike_BumperAd_6Sek_16x9.mp4",title:"15 JuFi24 Mountainbike BumperAd 6Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/16_JuFi24_Segelflug_BumperAd_6Sek_16x9.mp4",title:"16 JuFi24 Segelflug BumperAd 6Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/17_JuFi24_Festival_Pinterest_paid_12Sek_1x1.mp4",title:"17 JuFi24 Festival Pinterest paid 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/18_JuFi24_Travel_Pinterest_paid_12Sek_1x1.mp4",title:"18 JuFi24 Travel Pinterest paid 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/19_JuFi24_Mountainbike_Pinterest_paid_12Sek_1x1.mp4",title:"19 JuFi24 Mountainbike Pinterest paid 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/20_JuFi24_Segelflug_Pinterest_paid_12Sek_1x1.mp4",title:"20 JuFi24 Segelflug Pinterest paid 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/21_JuFi24_Festival_Instagram-Facebook_Ad_12Sek_4x5.mp4",title:"21 JuFi24 Festival Instagram-Facebook Ad 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/22_JuFi24_Travel_Instagram-Facebook_Ad_12Sek_4x5.mp4",title:"22 JuFi24 Travel Instagram-Facebook Ad 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/23_JuFi24_Mountainbike_Instagram-Facebook_Ad_12Sek_4x5.mp4",title:"23 JuFi24 Mountainbike Instagram-Facebook Ad 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/24_JuFi24_Segelflug_Instagram-Facebook_Ad_12Sek_4x5.mp4",title:"24 JuFi24 Segelflug Instagram-Facebook Ad 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/25_JuFi24_Festival_Instagram-Facebook_StoryAd_ReelAd_12Sek_9x16.mp4",title:"25 JuFi24 Festival Instagram-Facebook StoryAd ReelAd 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/26_JuFi24_Travel_Instagram-Facebook_StoryAd_ReelAd_12Sek_9x16.mp4",title:"26 JuFi24 Travel Instagram-Facebook StoryAd ReelAd 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/27_JuFi24_Mountainbike_Instagram-Facebook_StoryAd_ReelAd_12Sek_9x16.mp4",title:"27 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/28_JuFi24_Segelflug_Instagram-Facebook_StoryAd_ReelAd_12Sek_9x16.mp4",title:"28 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/29_JuFi24_Festival_Pinterest_Bumper_6Sek_1x1.mp4",title:"29 JuFi24 Festival Pinterest Bumper 6Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/30_JuFi24_Travel_Pinterest_Bumper_6Sek_1x1.mp4",title:"30 JuFi24 Travel Pinterest Bumper 6Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/31_JuFi24_Mountainbike_Pinterest_Bumper_6Sek_1x1.mp4",title:"31 JuFi24 Mountainbike Pinterest Bumper 6Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/32_JuFi24_Segelflug_Pinterest_Bumper_6Sek_1x1.mp4",title:"32 JuFi24 Segelflug Pinterest Bumper 6Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/33_JuFi24_Festival_Instagram-Facebook_Ad_Bumper_6Sek_4x5.mp4",title:"33 JuFi24 Festival Instagram-Facebook Ad Bumper 6Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/34_JuFi24_Travel_Instagram-Facebook_Ad_Bumper_6Sek_4x5.mp4",title:"34 JuFi24 Travel Instagram-Facebook Ad Bumper 6Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/35_JuFi24_Mountainbike_Instagram-Facebook_Ad_Bumper_6Sek_4x5.mp4",title:"35 JuFi24 Mountainbike Instagram-Facebook Ad Bumper 6Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/36_JuFi24_Segelflug_Instagram-Facebook_Ad_Bumper_6Sek_4x5.mp4",title:"36 JuFi24 Segelflug Instagram-Facebook Ad Bumper 6Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/37_JuFi24_Festival_Instagram-Facebook_StoryAd_ReelAd_Bumper_6Sek_9x16.mp4",title:"37 JuFi24 Festival Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/38_JuFi24_Travel_Instagram-Facebook_StoryAd_ReelAd_Bumper_6Sek_9x16.mp4",title:"38 JuFi24 Travel Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/39_JuFi24_Mountainbike_Instagram-Facebook_StoryAd_ReelAd_Bumper_6Sek_9x16.mp4",title:"39 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/40_JuFi24_Segelflug_Instagram-Facebook_StoryAd_ReelAd_Bumper_6Sek_9x16.mp4",title:"40 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/41_JuFi24_Festival_Instagram-Facebook_Post_12Sek_1x1.mp4",title:"41 JuFi24 Festival Instagram-Facebook Post 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/42_JuFi24_Travel_Instagram-Facebook_Post_12Sek_1x1.mp4",title:"42 JuFi24 Travel Instagram-Facebook Post 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/43_JuFi24_Mountainbike_Instagram-Facebook_Post_12Sek_1x1.mp4",title:"43 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/44_JuFi24_Segelflug_Instagram-Facebook_Post_12Sek_1x1.mp4",title:"44 JuFi24 Segelflug Instagram-Facebook Post 12Sek 1x1"},
    {url:"/media/assets/GSK_Junge_Finanzen/45_JuFi24_Festival_Instagram-Facebook_Post_12Sek_4x5.mp4",title:"45 JuFi24 Festival Instagram-Facebook Post 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/46_JuFi24_Travel_Instagram-Facebook_Post_12Sek_4x5.mp4",title:"46 JuFi24 Travel Instagram-Facebook Post 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/47_JuFi24_Mountainbike_Instagram-Facebook_Post_12Sek_4x5.mp4",title:"47 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/48_JuFi24_Segelflug_Instagram-Facebook_Post_12Sek_4x5.mp4",title:"48 JuFi24 Segelflug Instagram-Facebook Post 12Sek 4x5"},
    {url:"/media/assets/GSK_Junge_Finanzen/49_JuFi24_Festival_TikTok_12Sek_9x16.mp4",title:"49 JuFi24 Festival TikTok 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/50_JuFi24_Travel_TikTok_12Sek_9x16.mp4",title:"50 JuFi24 Travel TikTok 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/51_JuFi24_Mountainbike_TikTok_12Sek_9x16.mp4",title:"51 JuFi24 Mountainbike TikTok 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/52_JuFi24_Segelflug_TikTok_12Sek_9x16.mp4",title:"52 JuFi24 Segelflug TikTok 12Sek 9x16"},
    {url:"/media/assets/GSK_Junge_Finanzen/53_JuFi24_Trailer_DOOH_10Sek_16x9.mp4",title:"53 JuFi24 Trailer DOOH 10Sek 16x9"},
    {url:"/media/assets/GSK_Junge_Finanzen/54_JuFi24_Trailer_DOOH_10Sek_9x16.mp4",title:"54 JuFi24 Trailer DOOH 10Sek 9x16"}
  ];

  const nextVideo = React.useCallback(() => {
    if (videoIndex === videoData.length - 1) {

      setEndReached(true);

      setVideoIndex(0);

    } else {
      setVideoIndex(Number(videoIndex) + 1);
      setEndReached(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIndex]);

  const changeVideo = React.useCallback(() => {

    reactPlayerRef.current.getInternalPlayer().src = videoData[videoIndex].url;
    if (endReached) {
      reactPlayerRef.current.getInternalPlayer().pause();
    } else {
      reactPlayerRef.current.getInternalPlayer().play();
    }


    //videoUrls nicht als dependency benutzen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlayerRef, videoIndex, endReached]);

  const toggleLoop = () => {
    setLoopVideo(!loopVideo);
  }

  const setPlayerOnended = React.useCallback(() => {

    if (reactPlayerRef.current?.getInternalPlayer()) {

      let onEnded = () => { };
      if (loopVideo) {
        onEnded = () => nextVideo();
      }

      reactPlayerRef.current.getInternalPlayer().onended = onEnded;
    }

  }, [loopVideo, nextVideo, reactPlayerRef]);

  React.useEffect(() => {
    setPlayerOnended();
  }, [loopVideo, setPlayerOnended]);

  React.useEffect(() => {
    if (reactPlayerRef.current?.getInternalPlayer()) {

      setPlayerOnended();

      changeVideo();
    }

  }, [videoIndex, reactPlayerRef, setPlayerOnended, changeVideo]);



  // Scroll zur aktuellen Position des aktiven Videos
  React.useEffect(() => {
    if (playlistRef.current && playlistRef.current.children[videoIndex]) {
      const activeVideo = playlistRef.current.children[videoIndex];
      activeVideo.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [videoIndex]);

  /*
   <button className="toggle-loop-button" onClick={() => toggleLoop()}>
            {loopVideo ? '\u25A0 Manuell abspielen' : '\u25B6 Automatisch abspielen'}
          </button>
  */

  const updatePlaylistWidth = () => {
    //console.log('updatePlaylistWidth')
    if (reactPlayerRef.current && playlistRef.current) {
      const videoElement = reactPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        //print all properties of videoElement
        // for (var prop in videoElement) {
        //   console.log(prop, videoElement[prop]);
        // }
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;

        // console.log('videoWidth', videoWidth)
        // console.log('videoHeight', videoHeight)
        // console.log('aspectRatio', aspectRatio)

        const wrapper = reactPlayerRef.current.wrapper;
        const containerHeight = wrapper.getBoundingClientRect().height;
        const actualVideoWidth = containerHeight * aspectRatio;

        // console.log('containerHeight', containerHeight)
        // console.log('actualVideoWidth', actualVideoWidth)

        // console.log('current width', playlistRef.current.style.width)
        playlistRef.current.style.width = `${actualVideoWidth}px`;
        // console.log('new width', playlistRef.current.style.width)

      }
    }
  };

  const handleBestellstreckeChange = (value) => {
    setBestellstrecke(value);
  };

  React.useEffect(() => {
    updatePlaylistWidth();
  }, [reactPlayerRef, playlistRef]);

  const kostenMap = {
    "XXS": "199,00 Euro",
    "XS": "249,00 Euro",
    "S": "311,00 Euro",
    "M": "389,00 Euro",
    "L": "486,00 Euro",
    "XL": "607,00 Euro",
  };

  const bilanzKosten = kostenMap[authCtxData?.groesse];

  return (
    <div className={"Step C2Step0 c-Uebersicht"}>
      <div className={"big-video"}>
        <ReactPlayer
          ref={reactPlayerRef}
          url={videoData[videoIndex].url}
          controls={true}
          muted={true}
          onPlay={() => setPlayerOnended()}
          onReady={updatePlaylistWidth}
        />

        <div className="playlist-container" ref={playlistRef}>
          <div className="playlist-title">Playlist</div>

          <div className="video-list">
            {videoData.map((video, index) => (
              <div
                key={index}
                className={`video-item${videoIndex === index ? ' active' : ''}`}
                onClick={() => setVideoIndex(index)}
              >
                {video.thumbnail && <img src={video.thumbnail} alt={`Video ${index + 1}`} />}
                <div className="video-text">
                  <div className="video-title">{video.title}</div>
                  {video.description && <div className="video-description">{video.description}</div>}
                </div>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className={"content c-KampagnenBeschreibung"}>
        <h5 style={{ color: "grey" }}>Veröffentlicht am 01.05.2024</h5>
        <br />
        <h4>Beschreibung</h4>
        <p>Sie erhalten das komplette Videopaket der GSK Markenkampagne – Junge Finanzen individualisiert mit Ihrem Institutslogo.
        </p>

        <div
          className={"e-button"}
          onClick={() => {
            handleActiveStepChange(1);
            handleBestellstreckeChange(true);
          }}
        >
          Bestellung starten
        </div>


        <br />
        <div className="h-line"></div>
        <h4>Details: </h4>
        <ul>
          <li>Einsetzen des Logos Ihrer Sparkasse in alle Abbinder-Animationen</li>
          <li>
            Lieferung:
            <div class="scrollable-list">
            <ul>
            <li>09 JuFi24 Festival PreRoll 20Sek 16x9</li>
            <li>10 JuFi24 Travel PreRoll 20Sek 16x9</li>
            <li>11 JuFi24 Mountainbike PreRoll 20Sek 16x9</li>
            <li>12 JuFi24 Segelflug PreRoll 20Sek 16x9</li>
            <li>13 JuFi24 Festival BumperAd 6Sek 16x9</li>
            <li>14 JuFi24 Travel BumperAd 6Sek 16x9</li>
            <li>15 JuFi24 Mountainbike BumperAd 6Sek 16x9</li>
            <li>16 JuFi24 Segelflug BumperAd 6Sek 16x9</li>
            <li>17 JuFi24 Festival Pinterest paid 12Sek 1x1</li>
            <li>18 JuFi24 Travel Pinterest paid 12Sek 1x1</li>
            <li>19 JuFi24 Mountainbike Pinterest paid 12Sek 1x1</li>
            <li>20 JuFi24 Segelflug Pinterest paid 12Sek 1x1</li>
            <li>21 JuFi24 Festival Instagram-Facebook Ad 12Sek 4x5</li>
            <li>22 JuFi24 Travel Instagram-Facebook Ad 12Sek 4x5</li>
            <li>23 JuFi24 Mountainbike Instagram-Facebook Ad 12Sek 4x5</li>
            <li>24 JuFi24 Segelflug Instagram-Facebook Ad 12Sek 4x5</li>
            <li>25 JuFi24 Festival Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>26 JuFi24 Travel Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>27 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>28 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>29 JuFi24 Festival Pinterest Bumper 6Sek 1x1</li>
            <li>30 JuFi24 Travel Pinterest Bumper 6Sek 1x1</li>
            <li>31 JuFi24 Mountainbike Pinterest Bumper 6Sek 1x1</li>
            <li>32 JuFi24 Segelflug Pinterest Bumper 6Sek 1x1</li>
            <li>33 JuFi24 Festival Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>34 JuFi24 Travel Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>35 JuFi24 Mountainbike Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>36 JuFi24 Segelflug Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>37 JuFi24 Festival Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>38 JuFi24 Travel Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>39 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>40 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>41 JuFi24 Festival Instagram-Facebook Post 12Sek 1x1</li>
            <li>42 JuFi24 Travel Instagram-Facebook Post 12Sek 1x1</li>
            <li>43 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 1x1</li>
            <li>44 JuFi24 Segelflug Instagram-Facebook Post 12Sek 1x1</li>
            <li>45 JuFi24 Festival Instagram-Facebook Post 12Sek 4x5</li>
            <li>46 JuFi24 Travel Instagram-Facebook Post 12Sek 4x5</li>
            <li>47 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 4x5</li>
            <li>48 JuFi24 Segelflug Instagram-Facebook Post 12Sek 4x5</li>
            <li>49 JuFi24 Festival TikTok 12Sek 9x16</li>
            <li>50 JuFi24 Travel TikTok 12Sek 9x16</li>
            <li>51 JuFi24 Mountainbike TikTok 12Sek 9x16</li>
            <li>52 JuFi24 Segelflug TikTok 12Sek 9x16</li>
            <li>53 JuFi24 Trailer DOOH 10Sek 16x9</li>
            <li>54 JuFi24 Trailer DOOH 10Sek 9x16</li>
            </ul>
            </div>
          </li>
          <li>Es gelten für die individualisierten Medien die gleichen zentralen Nutzungsrechte der Kampagne, welche in der Kommunikationswelt-Planung (<a href="https://planung.kommunikationswelt.dsv-gruppe.de">https://planung.kommunikationswelt.dsv-gruppe.de</a>) einzusehen sind.</li>
        </ul>
        <div className="h-line"></div>
        <h4>Kosten: </h4>
        <p>Die Kosten sind nach Bilanzsumme gestaffelt:</p>
        {bilanzKosten && (
          <p>{authCtxData?.bilanzsumme} : <b>{bilanzKosten}</b> zzgl. MwSt.</p>
        )}

      </div>
    </div>
  );
};

export default CFJ24Step0;
