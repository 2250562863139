import * as React from "react";

import RenderButton from "../RenderButton";
import AuthContext from "../../../store/auth-context";
import { useDispatch, useSelector } from "react-redux";

const CSpStep2 = ({
  handleActiveStepChange,
  currentPage,
}) => {
  const authCtx = React.useContext(AuthContext);

  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  //Bankname vorgelegen
  const field = "urlText";

  let prevUserInput = useSelector((state) => state.userInput[field]);
  const [content, setContent] = React.useState(prevUserInput);

  const [isValidContent, setIsValidContent] = React.useState(true);

  const MAX_LENGTH = 40;

  const onChangeHandler = (event) => {


    //console.log(event.target.value);
    let inputLines = event.target.value.match(/\n/g) || [];

    let count = inputLines.length;
    let modValue = event.target.value;
    while (count >= 1) {
      const lastLinebreak = modValue.lastIndexOf("\n");
      modValue = modValue.substring(0, lastLinebreak);
      count = (modValue.match(/\n/g) || []).length;
    }

    if (modValue.length > MAX_LENGTH) {
      modValue = (modValue.substring(0, MAX_LENGTH));
    }

    //check if value is in format text.text
    let isValid = modValue.match(/^[a-zA-Z0-9äüö\-]{2,}[.][a-z]{2}[a-zA-Z0-9äüö\-\/]*$/);
    setContent(modValue);

    isValid = isValid && modValue.length <= 40;

    if (!isValid) {
      setIsValidContent(false);
      return;
    }

    setIsValidContent(true);

    dispatch({
      type: "addUserInput",
      payload: {
        value: modValue.trim(),
        field: field,
      },
    });
  };



  return (
    <div className={"Step C2Step2 c-Logoauswahl"}>
      <div className={"content"}>
        <h4>
          1. Falls gewünscht, trage hier die URL deiner Website ein.
        </h4>

        <div>
          <textarea
            className={"e-textarea" + (isValidContent ? "" : " textarea-invalid")}
            key="0"
            width="95"
            onChange={onChangeHandler}
            rows="3"
            defaultValue={"jetztallesmoeglich.de"}
            value={content}
            style={{ maxLines: 1 }}
            maxLength={MAX_LENGTH}
          ></textarea>
          <p>Tipps für kurze URLs</p>
          <ul>
            <li>Formuliere eine möglichst kurze und einfache URL – max. 40 Zeichen</li>
            <li>Formuliere die Kurz-URL ohne “https://www.” an Anfang, ohne “.html” am Ende und im Idealfall direkt als Top-Level-Domain – Bsp.: musterurl.de</li>
            <li>Zum Kürzen einer URL gibt es im S-Extranet unter <a href="https://extranet.sparkasse.de" target="_blank" rel="noopener noreferrer">extranet.sparkasse.de</a> den “Kurz-URL-Dienst: <i>s.de</i>”.<br /> Fragen dazu, beantwortet gerne das Support-Team der S-Communication
              Services per Telefon unter 030 246 36 - 700 oder E-Mail unter <a href="mailto:kundenbetreuung@s-communication.de">kundenbetreuung@s-communication.de</a>.</li>
          </ul>

        </div>


        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          {isValidContent ? (<RenderButton
            maxWidth="600px"
            key="1"
            campaignuuid="a1491c2f-0992-4ace-a0cc-14d3488013e0"
            weiterAction={handleActiveStepChange}
            weiterActionProp={currentPage + 1}

          />) : (<div className={"e-button gray"} title="Der eingegebene Text hat nicht das passende Format.">Bestätigen und Weiter</div>)}
        </div>
      </div>

      <div className={"content-sidebar"}>
        <div className={"big-image"}>
          <img src="/media/assets/spayment-dmc2023/jetztAllesMoeglich.jpg" alt="Beispielbild" />
          <p>
            <b>Beispielbild</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CSpStep2;
