import * as React from "react";
import ReactPlayer from "react-player";
import "./CampaignLBS_SSF.scss";
import { useSelector } from "react-redux";


const CSpStep0 = ({ handleActiveStepChange }) => {



  const reactPlayerRef = React.createRef();

  const [videoIndex, setVideoIndex] = React.useState(0);

  const [loopVideo, setLoopVideo] = React.useState(true);

  const [endReached, setEndReached] = React.useState(false);

  const campaignData = useSelector((state) => state.campaign);

  // Ref für die Playlist erstellen
  const playlistRef = React.useRef();

  const videoData = [
    {
      url: "/media/assets/LBS_Sofortfinanzierer_innen/LBSSofortfinanziererinnen_SparkasseMusterstadt_SPOT.mp4",
      //thumbnail: "/media/assets/podcast.jpg",
      title: "LBS Sofortfinanzierer:innen - Spot",
      //description: "Main 3",
    },
    {
      url: "/media/assets/LBS_Sofortfinanzierer_innen/LBSSofortfinanziererinnen_SparkasseMusterstadt_BUMPER.mp4",
      //thumbnail: "/media/assets/eigenheim.jpg",
      title: "LBS Sofortfinanzierer:innen - Bumper",
      //description: "Main 1",
    }
  ];

  const nextVideo = React.useCallback(() => {

    if (videoIndex === videoData.length - 1) {

      setEndReached(true);

      setVideoIndex(0);

    } else {
      setVideoIndex(Number(videoIndex) + 1);
      setEndReached(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIndex]);

  const changeVideo = React.useCallback(() => {

    reactPlayerRef.current.getInternalPlayer().src = videoData[videoIndex].url;
    if (endReached) {
      reactPlayerRef.current.getInternalPlayer().pause();
    } else {
      reactPlayerRef.current.getInternalPlayer().play();
    }


    //videoUrls nicht als dependency benutzen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlayerRef, videoIndex, endReached]);

  const toggleLoop = () => {
    setLoopVideo(!loopVideo);
  }

  const setPlayerOnended = React.useCallback(() => {

    if (reactPlayerRef.current?.getInternalPlayer()) {

      let onEnded = () => { };
      if (loopVideo) {
        onEnded = () => nextVideo();
      }

      reactPlayerRef.current.getInternalPlayer().onended = onEnded;
    }

  }, [loopVideo, nextVideo, reactPlayerRef]);

  React.useEffect(() => {
    setPlayerOnended();
  }, [loopVideo, setPlayerOnended]);

  React.useEffect(() => {
    if (reactPlayerRef.current?.getInternalPlayer()) {

      setPlayerOnended();

      changeVideo();
    }

  }, [videoIndex, reactPlayerRef, setPlayerOnended, changeVideo]);



  // Scroll zur aktuellen Position des aktiven Videos
  React.useEffect(() => {
    if (playlistRef.current && playlistRef.current.children[videoIndex]) {
      const activeVideo = playlistRef.current.children[videoIndex];
      activeVideo.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [videoIndex]);

  /*
   <button className="toggle-loop-button" onClick={() => toggleLoop()}>
            {loopVideo ? '\u25A0 Manuell abspielen' : '\u25B6 Automatisch abspielen'}
          </button>
  */

  const updatePlaylistWidth = () => {
    //console.log('updatePlaylistWidth')
    if (reactPlayerRef.current && playlistRef.current) {
      const videoElement = reactPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        //print all properties of videoElement
        // for (var prop in videoElement) {
        //   console.log(prop, videoElement[prop]);
        // }
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;

        // console.log('videoWidth', videoWidth)
        // console.log('videoHeight', videoHeight)
        // console.log('aspectRatio', aspectRatio)

        const wrapper = reactPlayerRef.current.wrapper;
        const containerHeight = wrapper.getBoundingClientRect().height;
        const actualVideoWidth = containerHeight * aspectRatio;

        // console.log('containerHeight', containerHeight)
        // console.log('actualVideoWidth', actualVideoWidth)

        // console.log('current width', playlistRef.current.style.width)
        playlistRef.current.style.width = `${actualVideoWidth}px`;
        // console.log('new width', playlistRef.current.style.width)

      }
    }
  };

  React.useEffect(() => {
    updatePlaylistWidth();
  }, [reactPlayerRef, playlistRef]);

  return (
    <div className={"Step C2Step0 c-Uebersicht"}>
      <div className={"big-video"}>
        <ReactPlayer
          ref={reactPlayerRef}
          url={videoData[videoIndex].url}
          controls={true}
          muted={true}
          onPlay={() => setPlayerOnended()}
          onReady={updatePlaylistWidth}
        />

        <div className="playlist-container" ref={playlistRef}>
          <div className="playlist-title">Playlist</div>

          <div className="video-list">
            {videoData.map((video, index) => (
              <div
                key={index}
                className={`video-item${videoIndex === index ? ' active' : ''}`}
                onClick={() => setVideoIndex(index)}
              >
                {video.thumbnail && <img src={video.thumbnail} alt={`Video ${index + 1}`} />}
                <div className="video-text">
                  <div className="video-title">{video.title}</div>
                  {video.description && <div className="video-description">{video.description}</div>}
                </div>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className={"content c-KampagnenBeschreibung"}>
        <h5 style={{ color: "grey" }}>Veröffentlicht am 17.03.2023</h5>
        <h4>Beschreibung</h4>
        {campaignData.allowed ? (<div>
          <p>Im Rahmen Ihrer LBS-Kampagnenbuchung über die Kommunikationswelt SEPP steht Ihnen die Video-Individualisierung der Kampagne „LBS Sofortfinanzierer:innen“ in VIA zur Verfügung.</p>
          <div
            className={"e-button"}
            onClick={() => {
              handleActiveStepChange(1);
            }}
          >
            Individualisierung starten
          </div>
        </div>) :
          (<div>
            <p>
              Für die Kampagne „LBS Sofortfinanzierer:innen“ erhalten Sie wenige Werktage nach Ihrer Kampagnenbuchung über die Kommunikationswelt SEPP eine Freischaltung, damit Sie Ihre Kampagnenvideos automatisiert individualisieren lassen können.
              <br />
              Für einen Kampagnenbuchung über Kommunikationswelt SEPP gehen Sie wie folgt vor:</p>
            <lu>
              <li>Öffnen Sie die Kommunikationswelt SEPP über den folgenden Link und melden Sie sich an: <a href="https://sepp.kommunikationswelt.dsv-gruppe.de" target="_blank" rel="noopener noreferrer">https://sepp.kommunikationswelt.dsv-gruppe.de</a></li>
              <li>Klicken Sie auf „Service buchen“</li>
              <li>Wählen Sie „LBS Sofortfinanzierung“ und folgen Sie den weiteren Anweisungen</li>
              <li>Einige Werktage nach Ihrer Kampagnenbuchung über die Kommunikationswelt SEPP erhalten Sie von uns eine Nachricht, dass das Template zur Video-Individualisierung in VIA für Sie freigeschaltet wurde. Sie können dann an dieser Stelle mit der Individualisierung starten.</li>
            </lu>
            <br />
            <div
              className={"e-button gray"}
            >Individualisierung starten</div>
          </div>)}


        <div className="h-line"></div>
        <h4>Details: </h4>
        <ul>
          <li>Einsetzen des Logos Ihrer Sparkasse im Abbinder</li>
          <li>
            Lieferung:
            <ul>
              <li>1x Video im Format 16:9, H.264/MP4, LBS Sofortfinanzierer:innen, Spot</li>
              <li>1x Video im Format 16:9, H.264/MP4, LBS Sofortfinanzierer:innen, Bumper</li>
            </ul>
          </li>
        </ul>

        <div className="h-line"></div>
        <h4>Kosten: </h4>
        <p>Für die Individualisierung der Videos der Kampagne „LBS Sofortfinanzierer:innen“ fallen keine zusätzlichen Kosten an. Diese sind bereits in den Kosten der Kampagnenbuchung über die Kommunikationswelt SEPP enthalten.</p>
      </div>
    </div>
  );
};

export default CSpStep0;
