import * as React from "react";

import "./CampaignGJF24.scss"

const CFJ24Step3 = ({
  campaignData,
  handleActiveStepChange,
  handleReset,
  currentPage,
  bestellstrecke,
}) => {
  return (
    <div className={"Step C2Step4 c-Datenschutz"}>
      <div className={"content"}>
        <div className={"datenschutz"}>
          <h4>
            Bitte bestätigen Sie die Nutzungsbedingungen für Medien dieser
            Kampagne.
          </h4>
          <br />
          <h4>1. Leistung von S-Communication Services</h4>
          <p>
            Die S-Communication Services GmbH („S-Com“) erbringt für das Institut folgende Leistungen:
          </p>
          <ul className="main">
            <li>46 Video-Individualisierungen: </li>
            <ul className="sub">
            <li>09 JuFi24 Festival PreRoll 20Sek 16x9</li>
            <li>10 JuFi24 Travel PreRoll 20Sek 16x9</li>
            <li>11 JuFi24 Mountainbike PreRoll 20Sek 16x9</li>
            <li>12 JuFi24 Segelflug PreRoll 20Sek 16x9</li>
            <li>13 JuFi24 Festival BumperAd 6Sek 16x9</li>
            <li>14 JuFi24 Travel BumperAd 6Sek 16x9</li>
            <li>15 JuFi24 Mountainbike BumperAd 6Sek 16x9</li>
            <li>16 JuFi24 Segelflug BumperAd 6Sek 16x9</li>
            <li>17 JuFi24 Festival Pinterest paid 12Sek 1x1</li>
            <li>18 JuFi24 Travel Pinterest paid 12Sek 1x1</li>
            <li>19 JuFi24 Mountainbike Pinterest paid 12Sek 1x1</li>
            <li>20 JuFi24 Segelflug Pinterest paid 12Sek 1x1</li>
            <li>21 JuFi24 Festival Instagram-Facebook Ad 12Sek 4x5</li>
            <li>22 JuFi24 Travel Instagram-Facebook Ad 12Sek 4x5</li>
            <li>23 JuFi24 Mountainbike Instagram-Facebook Ad 12Sek 4x5</li>
            <li>24 JuFi24 Segelflug Instagram-Facebook Ad 12Sek 4x5</li>
            <li>25 JuFi24 Festival Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>26 JuFi24 Travel Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>27 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>28 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd 12Sek 9x16</li>
            <li>29 JuFi24 Festival Pinterest Bumper 6Sek 1x1</li>
            <li>30 JuFi24 Travel Pinterest Bumper 6Sek 1x1</li>
            <li>31 JuFi24 Mountainbike Pinterest Bumper 6Sek 1x1</li>
            <li>32 JuFi24 Segelflug Pinterest Bumper 6Sek 1x1</li>
            <li>33 JuFi24 Festival Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>34 JuFi24 Travel Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>35 JuFi24 Mountainbike Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>36 JuFi24 Segelflug Instagram-Facebook Ad Bumper 6Sek 4x5</li>
            <li>37 JuFi24 Festival Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>38 JuFi24 Travel Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>39 JuFi24 Mountainbike Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>40 JuFi24 Segelflug Instagram-Facebook StoryAd ReelAd Bumper 6Sek 9x16</li>
            <li>41 JuFi24 Festival Instagram-Facebook Post 12Sek 1x1</li>
            <li>42 JuFi24 Travel Instagram-Facebook Post 12Sek 1x1</li>
            <li>43 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 1x1</li>
            <li>44 JuFi24 Segelflug Instagram-Facebook Post 12Sek 1x1</li>
            <li>45 JuFi24 Festival Instagram-Facebook Post 12Sek 4x5</li>
            <li>46 JuFi24 Travel Instagram-Facebook Post 12Sek 4x5</li>
            <li>47 JuFi24 Mountainbike Instagram-Facebook Post 12Sek 4x5</li>
            <li>48 JuFi24 Segelflug Instagram-Facebook Post 12Sek 4x5</li>
            <li>49 JuFi24 Festival TikTok 12Sek 9x16</li>
            <li>50 JuFi24 Travel TikTok 12Sek 9x16</li>
            <li>51 JuFi24 Mountainbike TikTok 12Sek 9x16</li>
            <li>52 JuFi24 Segelflug TikTok 12Sek 9x16</li>
            <li>53 JuFi24 Trailer DOOH 10Sek 16x9</li>
            <li>54 JuFi24 Trailer DOOH 10Sek 9x16</li>
            </ul>
            <li>
              Individualisierung der Videos entsprechend den Angaben in der
              Bestellstrecke
            </li>
          </ul>
          <p>
            Video-Individualisierungen außerhalb der vorgegebenen Optionen sind
            ausgeschlossen.
          </p>

          <h4>2. Lizenz & Nutzungsbedingungen</h4>
          <ul className="main">
           <li>Digital: 22.03.2024 bis einschließlich 21.03.2025<br />
              Das zeitlich beschränkte Recht, die Werbespots im Internet, Intranet, Social Media Dienste, Mobilen Diensten einzuspeisen und dort öffentlich vorzuführen - z. B. als Teaser, Banner, PreRoll oder auf Microsites. Miteingeschlossen ist das Recht, die Spots einer Vielzahl von Nutzern so zur Verfügung zu stellen, dass diese auf Abruf mittels eines Fernseh-, Computer-, Mobil- oder sonstigen Geräts die Spots empfangen und wiedergeben können.</li>
            <li>PoS: 22.03.2024 bis einschließlich 21.03.2025<br />
              Das zeitlich beschränkte Recht die Werbespots am Points of Sale, d.h. Verkaufs-, Angebots- und Präsentationsorten und -veranstaltungen, sowie Geschäftsstellen, an (Verkaufs-) Ausstellungen, Kongressen, Schulungsveranstaltungen, auf Messen und ähnlichen Veranstaltungen öffentlich vorzuführen.</li>
          </ul>
          <p>
            Nach Ablauf der Nutzungsfrist dürfen keinerlei Kommunikationsmittel (digital und print) mehr eingesetzt
            oder in Umlauf gebracht werden, die oben genanntes Bildmaterial zeigen. Sofern die Einstellung auf Social
            Media Plattformen zulässig ist, sind insbesondere diese nach Ablauf der Frist zu entfernen. Ob
            zwischenzeitlich eine Verlängerung des Nutzungszeitraums erfolgt ist, können Sie dem digitalen Bildarchiv
            der S-Com entnehmen oder bei der Kundenberatung erfragen.</p>
          <div className="einruecken">




            <br />
            <h4>A c h t u n g !</h4>
            <h4><u>Bitte beachten Sie aus lizenzrechtlichen Gründen folgendes:</u></h4>
            <p>
              Es ist ausdrücklich nur gestattet Werbespots der jeweils aktuellen GSK im angegebenen Nutzungszeitraum innerhalb Deutschlands zu nutzen bzw. einzubinden. Für ältere Werbespots oder Versionen bestehen keine Nutzungsrechte mehr, ein Einsatz ist daher nicht möglich. Eine Nutzung außerhalb der angegebenen Nutzungszeiträume führt zu Lizenzverstößen und berechtigt die Rechteinhaber zu Unterlassungsaufforderungen und hohen Schadenersatzforderungen gegenüber den nutzenden Sparkassen.
            </p>
          </div>

          <h4>3. Nicht-Übertragbarkeit</h4>
          <p>Die hier eingeräumten Nutzungsrechte sind nicht auf Dritte übertragbar.</p>

          <h4>4. Bildbearbeitungsrechte</h4>
          <p>Die Bearbeitung, Umgestaltung oder Manipulation des Bildmotivs ist unzulässig. Dies gilt insbesondere für jegliche Entstellung, beispielsweise durch Nachfotografieren, zeichnerische Verfälschung, Fotocomposing oder andere Hilfsmittel. Ausgenommen davon ist die Veränderung der Bildgröße (Vergrößerung/Verkleinerung) und die Farbraumkonvertierung.
          </p>

          <h4>5. Freistellung/Verantwortung bei Verstößen gegen diese Nutzungsbedingungen</h4>
          <p>Im Falle des Verstoßes gegen den Nutzungsvertrag, insbesondere bei unberechtigter Nutzung, Bearbeitung
            oder Weitergabe der Inhalte oder Teile daraus, hat das Institut die S-Com von allen sich hieraus ergebenden
            Ansprüchen Dritter freizustellen und alle der S-Com entstehenden Schäden zu ersetzen.</p>

          <h4>6.Wirksamwerden/Laufzeit</h4>
          <p>Mit Bestätigung des Buttons „Bestätigen und Video(s) kostenpflichtig erstellen“ akzeptieren Sie die oben genannten Lizenzbedingungen. Der Vertrag endet automatisch mit dem Ablauf der Nutzungsrechte der Videos.</p>

          <p>Ergänzend gelten die Nutzungsbedingungen VIA sowie die Regelungen des zwischen den
            Vertragsparteien geschlossenen Rahmenvertrages.</p>

        </div>



        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          {
            <div
              className={"e-button"}
              onClick={() => {
                handleReset();
                handleActiveStepChange(currentPage + 1);
              }}
            >
              Bestätigen und
              Video(s) kostenpflichtig erstellen
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default CFJ24Step3;
